<dx-accordion (onInitialized)="onInitAccordion($event)">
    <dxi-item [title]='L("DETAILS")' *ngIf="edit.model.items && edit.model.items.length > 0">
        <dx-form [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation">
            <dxi-item itemType="group" [colCount]="2" cssClass="twelve-margin">
                <dxi-item [label]="{text: L('NO') }" dataField="refNumber" editorType="dxTextBox"
                    [editorOptions]="{ readOnly: true }">
                </dxi-item>
                <dxi-item [label]="{text: L('TITLE') }" dataField="title" editorType="dxTextBox"
                    [editorOptions]="{ maxLength : 512 }">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
            </dxi-item>

            <dxi-item itemType="group" [colCount]="2" cssClass="twelve-margin dx-hover-gray-bg">
                <dxi-item [label]="{text: L('TYPE') }" dataField="typeId" editorType="dxSelectBox"
                    [editorOptions]="{ dataSource: ds.static.rfqType, valueExpr: 'id', displayExpr: 'name', onValueChanged: onTypeChange }">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: L('FLOW_TYPE') }" dataField="flowType" editorType="dxSelectBox"
                    [editorOptions]="{ dataSource: ds.static.flowType, valueExpr: 'id', displayExpr: 'name', readOnly: edit.model.typeId != 2 }">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: L('PRIORITY') }" dataField="priorityId" editorType="dxSelectBox"
                    [editorOptions]="{ dataSource: rfq.priority, valueExpr: 'id', displayExpr: 'description' }">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: L('LIMIT_DATE') }" dataField="dueDate" editorType="dxDateBox"
                    [editorOptions]="{ displayFormat: 'dd.MM.yyyy HH:mm', type: 'datetime', min: currentDate }">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
            </dxi-item>

            <dxi-item itemType="group" [colCount]="2" cssClass="twelve-margin">
                <dxi-item [label]="{text: L('EVALUATION_MATRIX') }" dataField="evaluationMatrixId"
                    editorType="dxSelectBox"
                    [editorOptions]="{ dataSource: rfq.evMatrix, valueExpr: 'id', displayExpr: 'name' }">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{ visible: false }" dataField="automated" editorType="dxCheckBox"
                    [editorOptions]="{ text: L('AUTOMATION') }">
                </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2" cssClass="twelve-margin">
                <dxi-item [label]="{text: L('PARTY_NOTES') }" dataField="partyNotes" editorType="dxTextArea"
                    [editorOptions]="{ height: 125 }">
                </dxi-item>
                <dxi-item [label]="{text: L('PARTY_NOTES_ENG') }" dataField="partyNotesEng" editorType="dxTextArea"
                    [editorOptions]="{ height: 125 }">
                </dxi-item>
                <dxi-item [label]="{text: L('INTERNAL_NOTES') }" dataField="internalNotes" editorType="dxTextArea"
                    [editorOptions]="{ height: 125 }" [colSpan]="2">
                </dxi-item>
            </dxi-item>
        </dx-form>
    </dxi-item>

    <dxi-item [title]='L("SUPPLIERS")' *ngIf="edit.model.items && edit.model.items.length > 0">
        <app-supplier-select [supplier]="rfq.supplier" [selectMode]="edit.model.typeId == 3 ? 'single' : 'multiple'">
        </app-supplier-select>
    </dxi-item>

    <dxi-item [title]='L("SELECTED_RN_ITEMS")' *ngIf="edit.model.items && edit.model.items.length > 0">
        <dx-data-grid [dataSource]="edit.dsSelectedItems" [showColumnHeaders]="true" [height]="300">

            <dxi-column [caption]='L("RN_NO")' dataField='ri_rnNo' dataType='string' [groupIndex]="0"
                [customizeText]="ds.stringZero"></dxi-column>
            <dxi-column [caption]='L("RN_POS_NO")' dataField='ri_rnPosNo' dataType='string'
                [customizeText]="ds.stringZero"></dxi-column>
            <dxi-column [caption]='L("REQUESTER")' [isBand]="true" headerCellTemplate="reqBand">
                <dxi-column [caption]='L("REQUESTER")' dataField='ri_requesterName' dataType='string'></dxi-column>
                <dxi-column [caption]='L("REQUEST_REASON")' dataField='ri_reason' dataType='string'></dxi-column>
                <dxi-column [caption]='L("DOC_TYPE")' dataField='doc_description' dataType='string'></dxi-column>
                <dxi-column [caption]='L("ACCOUNT")' dataField='ri_account' dataType='string'></dxi-column>
                <dxi-column [caption]='L("DEPARTMENT")' dataField='dep_description' dataType='string'></dxi-column>
            </dxi-column>


            <dxi-column [caption]='L("MATERIAL")' [isBand]="true" headerCellTemplate="matBand">
                <dxi-column [caption]='L("CODE")' dataField='m_code' dataType='string' [allowSearch]="false"
                    [customizeText]="ds.stringZero">
                </dxi-column>
                <dxi-column [caption]='L("NAME")' dataField='m_name' dataType='string'></dxi-column>
                <dxi-column [caption]='L("NAME_ENG")' dataField='m_nameEng' dataType='string'></dxi-column>
                <dxi-column [caption]='L("GROUP")' dataField='mg_description' dataType='string' [allowEditing]="false">
                </dxi-column>
                <dxi-column [caption]='L("TYPE")' dataField='m_typeId' dataType='number' [allowSearch]="false">
                    <dxo-lookup [dataSource]="ds.static.materialType" valueExpr="id" displayExpr="name"></dxo-lookup>
                </dxi-column>
                <dxi-column [caption]='L("QUANTITY")' dataField='ri_quantity' dataType='number' [allowSearch]="false">
                </dxi-column>
                <dxi-column [caption]='L("UOM")' dataField='uom_name' dataType='string'
                    [allowEditing]="false"></dxi-column>
                <dxi-column [caption]='L("REFERENCE_PRICE")' dataField='ri_price' dataType='number'
                    [allowSearch]="false">
                </dxi-column>
                <dxi-column [caption]='L("CURRENCY")' dataField='ri_currencyId' dataType='string'>
                    <dxo-lookup [dataSource]="rfq.currency" valueExpr="id" displayExpr="name"></dxo-lookup>
                </dxi-column>
            </dxi-column>


            <div *dxTemplate="let r of 'reqBand'" class="dx-datagrid-text-content" role="presentation">
                <i class="fa-solid fa-code-pull-request"></i>
                {{L("REQUESTER")}}
            </div>
            <div *dxTemplate="let r of 'matBand'" class="dx-datagrid-text-content" role="presentation">
                <i class="fa-solid fa-box"></i>
                {{L("MATERIAL")}}
            </div>

        </dx-data-grid>
    </dxi-item>
    <!-- <dxi-item [title]='L("EVALUATION_COMMITTEE")'>
        <dx-list [height]="300" [dataSource]="rfq.committee" displayExpr="u_fullName" keyExpr="u_id"
            [searchEnabled]="true" searchExpr="u_fullName" [showSelectionControls]="true" selectionMode='multiple'
            [(selectedItemKeys)]="edit.model.committee">
        </dx-list>
    </dxi-item> -->
</dx-accordion>