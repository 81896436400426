<dx-scroll-view [height]="'calc(100vh - 170px)'" [width]="400" class="white-bg">
    <div class="form-box">
        <dx-form [formData]="edit.model" [colCount]="2" [visible]="!collapsed"
            (onFieldDataChanged)="formFieldChanged($event)"
            (onOptionChanged)="formOptionChanged($event)">
            <dxi-item [label]="{text: L('TITLE')}" dataField="title" editorType="dxTextArea"
                [editorOptions]="{ readOnly: true }" [colSpan]="2">
            </dxi-item>
            <dxi-item [label]="{text: L('TYPE')}" dataField="typeId" editorType="dxSelectBox" [editorOptions]="{ dataSource: ds.static.rfqType, valueExpr: 'id', displayExpr: 'name', 
            readOnly: edit.model && edit.model.statusId != 0 }"></dxi-item>
            <dxi-item [label]="{text: L('FLOW_TYPE') }" dataField="flowType" editorType="dxSelectBox" [editorOptions]="{ dataSource: ds.static.flowType, valueExpr: 'id', displayExpr: 'name',
                    readOnly: edit.model && (edit.model.statusId != 0 || edit.model.typeId != 2)  }">
            </dxi-item>
            <dxi-item [label]="{text: L('EVALUATION_MATRIX') }" dataField="evaluationMatrixId" editorType="dxSelectBox"
                [editorOptions]="{ dataSource: rfq.evMatrix, valueExpr: 'id', 
            displayExpr: 'name', readOnly: edit.model && edit.model.statusId != 0 }" [colSpan]="2"></dxi-item>

            <dxi-item [label]="{text: L('PRIORITY') }" dataField="priorityId" editorType="dxSelectBox" [editorOptions]="{ dataSource: rfq.priority, valueExpr: 'id', displayExpr: 'description', 
readOnly: edit.model && edit.model.statusId != 0 }"></dxi-item>
            <dxi-item [label]="{ visible: false }" dataField="automated" editorType="dxCheckBox"
                [editorOptions]="{ text: L('AUTOMATION'), readOnly: edit.model && edit.model.statusId != 0 }">
            </dxi-item>
            <dxi-item [label]="{text: L('LIMIT_DATE') }" dataField="dueDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd.MM.yyyy HH:mm', type: 'datetime', 
                readOnly: edit.model && (edit.model.statusId > 12 || edit.model.statusId < 0) }" [colSpan]="2">
            </dxi-item>
            <dxi-item [label]="{text: L('INTERNAL_NOTES') }" dataField="internalNotes" editorType="dxTextArea"
                [editorOptions]="{ readOnly: edit.model && edit.model.statusId >= 20, height: 200 }" [colSpan]="2">
            </dxi-item>
            <dxi-item [label]="{text: L('PARTY_NOTES') }" dataField="partyNotes" editorType="dxTextArea" [colSpan]="2"
                [editorOptions]="{ readOnly: edit.model && (edit.model.statusId > 12 || edit.model.statusId < 0), height: 200 }">
            </dxi-item>
            <dxi-item [label]="{text: L('PARTY_NOTES_ENG') }" dataField="partyNotesEng" editorType="dxTextArea"
                [colSpan]="2"
                [editorOptions]="{ readOnly: edit.model && (edit.model.statusId > 12 || edit.model.statusId < 0), height: 200 }">
            </dxi-item>
        </dx-form>
        <!-- <dx-button class="headbtn" type="normal" [text]="L('NOTIFY_CHANGES')" (onClick)="sendNotifications()"
            [visible]="edit.model && [5, 12].includes(edit.model.statusId)"></dx-button> -->
    </div>
</dx-scroll-view>