import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { EntityConditionGroup, EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { ModalFileService } from 'src/app/common/modal-file/modal-file.service';
import { RejectReasonService } from '../../reject-reason/reject-reason.service';
import { alert, confirm } from 'devextreme/ui/dialog';
import { InterestConflictService } from '../interest-conflict/interest-conflict.service';
import DataSource from 'devextreme/data/data_source';
import { DataService } from 'src/app/services';

@Component({
  selector: 'app-technical-quotation-list',
  templateUrl: './technical-quotation-list.component.html'
})
export class TechnicalQuotationListComponent extends iBisViewComponent implements OnInit {

  @Input() rfq!: any | null;
  @Input() height!: string;
  @ViewChild('gridTechApp') override grid!: DxDataGridComponent;
  quotationList!: DataSource;
  constructor(lg: iBisLanguageService, iui: iBisUIService, route: ActivatedRoute, private ds: DataService,
    private rr: RejectReasonService, public techFile: ModalFileService, private entity: iBisEntityService,
    private auth: iBisAuthService, public ic: InterestConflictService, private router: Router) {
    super((<any>null), lg, iui, route);
  }

  ngOnInit(): void {
    if (this.rfq) {
      this.quotationList = new DataSource(this.techQuoteList(this.rfq.id));
    }
  }
  techQuoteList(rfqId: string): any {
    const cg = new EntityConditionGroup();
    cg.useOr = true;
    cg.eq("riq.unavailable", false);
    cg.eq("riq.unavailable", null);
    const q = new EntityQuery('RFQItemQuoteTechUserView').eq('ri.rfqId', rfqId).eq('tu.userId', this.auth.user.id).addOrderBy(['p.name', 'm.name']);
    q.conditionGroups.groups.push(cg);
    return this.entity.store(q, false, 'riq_id');
  }

  getIncoTerms(code: string) {
    var ts = this.ds.static.incoTerm.find((r: any) => r.code == code);
    return ts ? ts.name : '-';
  }

  onApproveQuotation = (ev: any) => {
    confirm(this.L('CONFIRM_APPROVE'), this.L('ALERT')).then(resp => {
      if (resp) {
        this.rr.updateTechStatusQuote(ev.row.data.tus_id, ev.row.data.riq_id, ev.row.data.tu_id,  1, '').then(() => {
          this.grid.instance.refresh();
        }, error => {
          alert(this.lg.format(error), this.lg.format("ALERT")).then(resp => {
            if (error == 'CP103') {
              this.router.navigateByUrl('/technical-new');
            }
          })
        });
      }
    });
  }
  onAttachClick = (ev: any) => {
    this.techFile.showPopup({ id: ev.row.data.rp_id })
  }

  approveVisible = (ev: any) => {
    return ev.row.data && (ev.row.data.tus_techStatus == 2 || ev.row.data.tus_techStatus == null) && this.rfq.flowType == 1 &&
      this.rfq.statusId == 10;
  }

  rejectVisible = (ev: any) => {
    return ev.row.data && ev.row.data.tus_techStatus !== 0 && this.rfq.flowType == 1 &&
      this.rfq.statusId == 10;
  }

  clarificationVisible = (ev: any) => {
    return ev.row.data && this.rfq.flowType == 1 && this.rfq.statusId == 10 && ev.row.data.tus_techStatus !== 2;
  }

  clarificationTipsVisible = (ev: any) => {
    return ev.row.data && this.rfq.flowType == 1 && this.rfq.statusId == 10 && ev.row.data.tus_techStatus == 2;
  }

  attachVisible = (ev: any) => {
    return ev.row.data && ev.row.data.fc_fileNo;
  }

  returnVisible = (ev: any) => {
    return ev.row.data && ev.row.data.tus_techStatus == 0 && this.rfq.flowType == 1 &&
      this.rfq.statusId == 10;
  }

  onClarificationQuotation = (ev: any) => {
    this.rr.title = this.L('REQUEST_CLARIFICATIONS');
    this.rr.showPopup({
      id: ev.row.data.tus_id,
      entityFileId: ev.row.data.rp_entityFileId,
      name: 'TechUserStatus',
      flowType: this.rfq.flowType,
      product: ev.row.data.m_name,
      ri_compusNo: ev.row.data.ri_compusNo,
      dep_description: ev.row.data.dep_description,
      ri_requesterName: ev.row.data.ri_requesterName,
      techStatus: 2,
      quoteId: ev.row.data.riq_id,
      techUserId: ev.row.data.tu_id,
      supplier: ev.row.data.p_name,
      materialName: ev.row.data.m_name,
      manufacturer: ev.row.data.riq_manufacturer,
      isAlternate: ev.row.data.riq_isAlternate,
      alternateName: ev.row.data.riq_alternateName,
      observations: ev.row.data.riq_observations
    }, true).then((resp) => {
      if (resp) {
        notify(this.L('SUCCESSFULL_CLARIFICATION'), 'success', 3000);
      }
      if (this.grid) {
        this.grid.instance.refresh();
      }
    });
  }

  onClarificationClick = (ev: any) => {
    this.techFile.showPopup({ id: ev.row.data.rp_entityFileId });
  }

  onRejectQuotation = (ev: any) => {
    this.rr.title = this.L('REJECT_QUOTATION');
    this.rr.showPopup({
      id: ev.row.data.tus_id,
      entityFileId: ev.row.data.rp_entityFileId,
      name: 'TechUserStatus',
      flowType: this.rfq.flowType,
      product: ev.row.data.m_name,
      ri_compusNo: ev.row.data.ri_compusNo,
      ri_department: ev.row.data.ri_department,
      ri_requesterName: ev.row.data.ri_requesterName,
      techStatus: 0,
      quoteId: ev.row.data.riq_id,
      techUserId: ev.row.data.tu_id,
      supplier: ev.row.data.p_name,
      materialName: ev.row.data.m_name,
      manufacturer: ev.row.data.riq_manufacturer,
      isAlternate: ev.row.data.riq_isAlternate,
      alternateName: ev.row.data.riq_alternateName,
      observations: ev.row.data.riq_observations
    }, true).then((resp) => {
      if (resp) {
        notify(this.L('SUCCESSFULL_REJECT'), 'success', 3000);
      }
      if (this.grid) {
        this.grid.instance.refresh();
      }
    });
  }

  cellPrepared(ev: any) {
    if (ev.rowType === 'data' && ev.data.riq_techStatus == 0 && !ev.cellElement.classList.hasOwnProperty('red-txt')) {
      ev.cellElement.classList.add('red-txt');
    } else if (ev.rowType === 'data' && ev.data.riq_techStatus == 1 && !ev.cellElement.classList.hasOwnProperty('green-txt')) {
      ev.cellElement.classList.add('green-txt');
    }
  }

  override toolbarPreparing(ev: any) {
    super.toolbarPreparing(ev, false);
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'warning',
        name: 'conflict',
        text: this.L('REPORTING_CONFLICT_OF_INTERESTS'),
        onClick: () => {
          this.ic.showPopup(this.rfq.id, true).then((resp) => {
            if (resp) {
              alert(this.L('THANK_YOU_FOR_REPORTING'), this.L('ALERT'));
              this.router.navigateByUrl('technical-new');
            }
          })
        }
      },
      location: 'after'
    });
  }
}
