import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { Subject } from 'rxjs';
import { DataService } from '../../../app/services';

@Injectable({
  providedIn: 'root'
})
export class RfqEditService extends iBisSecureEditService {
  rfqItems!: DataSource;
  rfqItemQuote!: DataSource;
  supplier!: DataSource;
  // dsOrder!: CustomStore;
  gridHeight: string = 'calc(100vh - 200px)';
  leftTitle: string = '';

  priceItemSource: any[] = [];
  rfqSubject: Subject<any>;
  rfqItemSum!: any[];
  clarifications!: DataSource<any, any>;
  constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig,
    lg: iBisLanguageService, public ds: DataService, private router: Router) {
    super(auth, entity, config, lg);
    this.rfqSubject = new Subject();
  }

  protected load(): void {
    var etq = new EntityQuery('EnumType').eq("code", "MAT_PRICE_RO").eq("code", "SRV_PRICE_RO").eq("code", "MAT_PRICE_EN").eq("code", "SRV_PRICE_EN");
    etq.conditionGroups.useOr = true;
    this.entity.load(new EntityQuery('EnumValue').link('typeId', 'id', etq).addOrderBy(['orderIndex'])).then(data => {
      this.priceItemSource = data;
    })
  }

  onSaveRFQ(obj: any) {
    return this.entity.save('RFQ', this.model.id, obj).then(() => {
      notify(this.L('SAVED_SUCCESSFULLY'), 'success', 3000);
    }, err => {
      this.lg.showError(err);
    })
  }

  initData(rfqId: string) {
    this.entity.load(new EntityQuery('RFQItemSum').eq('ris.rfqId', rfqId).addOrderBy(['m.name'])).then((resp) => {
      this.rfqItemSum = resp;
    });
    this.rfqItems = new DataSource(this.entity.store(new EntityQuery('RFQItemView').eq('ri.rfqId', rfqId).addOrderBy(['ri.rnNo', 'ri.rnPosNo']), false, 'ri_id', undefined,
      (item) => {
        item.hasWinner = item.winner != null;
        item.fullName = `<div class='no-margin'><h3>${item.m_name}</h3><p>${this.lg.format('NAME_ENG')}: <b>${item.m_nameEng || '-'}</b></p><p>${this.lg.format('CODE')}: <b>${this.ds.stringZero({ value: item.m_code })}</b></p></div>`;
      }));
    this.clarifications = new DataSource(this.entity.store(new EntityQuery('Clarification').eq('rfqId', rfqId).addOrderByDesc(['created'])));
    this.rfqItemQuote = new DataSource(this.entity.store(new EntityQuery('RFQItemQuoteView').eq('ri.rfqId', rfqId).neq("riq_id", null).addOrderBy(['ri.rnNo', 'ri.rnPosNo']).addOrderByDesc(['riq.score']), false, 'riq_id'));
    this.supplier = new DataSource(this.entity.store(new EntityQuery('RFQPartyView').eq('rp.rfqId', rfqId).addOrderBy(['p.name']), false, 'rp_id'));
    // this.initOrderData(rfqId);
    this.getById(rfqId).then((resp) => {
      if (resp) {
        this.model = resp;
        this.rfqSubject.next(resp);
        this.leftTitle = this.getLeftTitle(resp.refNumber, resp.statusId);
      } else {
        notify(this.L('RFQ_NOT_FOUND'), 'error', 5000);
        this.router.navigateByUrl('rfquotation');
      }
    }, err => {
      this.lg.showError(err);
    });
  }

  // initOrderData(rfqId: string) {
  //   this.dsOrder = this.entity.store(new EntityQuery('OrderHeader').eq('rfqId', rfqId).addOrderByDesc(['orderDate']), false, 'id');
  // }

  createDefault() {
    this.model = {};
  }
  getById(id: string, serverUrl?: string): Promise<any> {
    return this.entity.getById('RFQ', id);
  }
  onRemove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  onSaveEv(serverUrl?: string): Promise<any> {
    throw new Error('Method not implemented.');
  }
  reset(): void {
    this.model = this.createDefault();
  }

  public saveNextStatus(statusId: number) {
    this.entity.save('RFQ', this.model.id, { statusId: statusId }).then((resp: any) => {
      notify(this.getSuccessMsg(statusId), 'success', 3000);
      this.initData(this.model.id);
    }, err => {
      if (err == 'CP100' || err == 'CP101') {
        alert(this.L(err), this.L('ALERT'));
      } else {
        this.lg.showError(err);
      }
    });
  }

  getSuccessMsg(statusId: number) {
    switch (statusId) {
      case 5: return this.L('SUCCESS_PUBLISH');
      case 10: return this.L('SUCCESS_TECHNICAL');
      case 20: return this.L("SUCCESS_FINALIZATION");
      default: return this.L("SUCCESS");
    }
  }

  getLeftTitle(refNumber: string, statusId: number) {
    var statusTxt = (<any>this.ds.static.rfqStatus).find((x: any) => x.id == statusId)?.name;
    return 'Cerere de ofertă - ' + refNumber + ' | ' + statusTxt;
  }

  public recalculateScore(rfqId: string) {
    return this.entity.execute('RecalculateScore', { rfqId: rfqId });
  }

  public getQuoteItems(rfqItemId: string): CustomStore {
    return this.entity.store(new EntityQuery('RFQItemQuoteView').eq('riq.rfqItemId', rfqItemId).addOrderBy(['m.name']).addOrderByDesc(['riq.score']), false, 'riq_id');
  }

  public getSupplierInclude(rfqItemId: string): CustomStore {
    return this.entity.store(new EntityQuery('RFQPartyItemIgnoreView').eq('ri.id', rfqItemId).addOrderBy(['p.name']), false, 'rp_id');
  }

  public excludeSupplier(partyId: string, rfqItemId: string) {
    return this.entity.insert('RFQPartyItemIgnore', { partyId: partyId, rfqItemId: rfqItemId });
  }

  public includeSupplier(partyItemIgnoreId: string) {
    return this.entity.remove('RFQPartyItemIgnore', partyItemIgnoreId);
  }

  public markAsWinner(riqId: string, win: boolean): Promise<any> {
    return this.entity.update('RFQItemQuote', riqId, { isWinner: win });
  }

  public approveCommercial(riqId: string): Promise<any> {
    return this.entity.update('RFQItemQuote', riqId, { commRejected: true });
  }

  public getAuditRecord(entityId: string): Promise<CustomStore> {
    return new Promise((resolve, reject) => {
      this.entity.load(new EntityQuery('RFQItemQuoteView').eq('ri.rfqId', entityId).push('ri.id', 'rp.id', 'riq.id').addOrderBy(['m.name'])).then((data) => {
        var entityIdArr: string[] = [entityId];
        data.forEach((element: any) => {
          if (element.ri_id) entityIdArr.push(element.ri_id);
          if (element.rp_id) entityIdArr.push(element.rp_id);
          if (element.riq_id) entityIdArr.push(element.riq_id);
        });
        const q = new EntityQuery('AuditRecordView').in('ar.entityId', entityIdArr).addOrderByDesc(['ar.updateTime']);
        resolve(this.entity.store(q, false, 'ar_id', this.config.saasServer));
      });
    })
  }

  public getEmailRecord(): Promise<any> {
    return new Promise((resolve, reject) => {
      const q = new EntityQuery("EMailQueue").eq("appId", this.auth.companyId).like("subject", "%" + this.model.refNumber + "%").addOrderByDesc(['created']);
      this.entity.load(q, this.config.saasServer).then((emailResp: any[]) => {
        var result = emailResp;
        const qn = new EntityQuery('NotifySupplierView').eq('ns.rfqId', this.model.id).addOrderByDesc(['eti.created']);
        this.entity.load(qn).then(notifyResp => {
          notifyResp.forEach((el: { p_emailAddress: any; ns_subject: any; ns_message: any; eti_created: any; }) => {
            result.push({ to: el.p_emailAddress, subject: el.ns_subject, body: el.ns_message, created: el.eti_created });
          });
          resolve(result.sort((a, b) => { return new Date(b.created).getTime() - new Date(a.created).getTime() }));
        })
      });
    })
  }

  updateRFQType(newTypeId: number) {
    return this.entity.update("RFQ", this.model.id, { typeId: newTypeId });
  }
}
