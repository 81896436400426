<app-quotation-message *ngIf="!isRFQVisible" (openEvent)="onOpenDetails($event)"></app-quotation-message>

<div class="container" *ngIf="isRFQVisible">
    <div class="header-box">
        <div class="box-1st">
            <img class="logo" src="assets/img/logo.png">
            <p style="display:inline-block;">
                <span class="rank" *ngIf="qs.rfqModel.typeId == 1 && qs.rfqModel.statusId >= 12">{{L('BID_POSITION')}}:
                    {{qs.partyRank}}</span>
            </p>
            <div class="party-info" *ngIf="qs.party">
                <p>{{L('NAME')}}: <b>{{qs.party.name}}</b></p>
                <p>{{L('PIN')}}: <b>{{qs.party.cif}}</b></p>
                <p>{{L('PHONE')}}: <b>{{qs.party.phone}}</b></p>
                <p>{{L('EMAIL_ADDRESS')}}: <b
                        [title]="qs.party.emailAddress">{{setShortEmail(qs.party.emailAddress)}}</b></p>
            </div>
            <p class="manual">
                <i class="fa-solid fa-book" (click)="openManual()"></i>
                {{L('MANUAL')}}
            </p>
            <p class="btn-action">
                <dx-button type="success"
                    [text]="qs.partyRfq.techStatus != 2 ? L('SEND_OFFER') : L('SEND_CLARIFICATION')"
                    (onClick)="qs.sendOffer($event)"
                    [visible]="qs.rfqModel.statusId < 20 && qs.rfqModel.techStatus != 2"
                    validationGroup="rfqPartyValidation"></dx-button>
                <dx-button type="danger" [text]="L('CANCEL_REGISTER')" (onClick)="qs.updateRFQRegister(false)"
                    [visible]="qs.rfqModel.statusId == 5"></dx-button>
            </p>
        </div>
        <div class="supplier-form">
            <dx-form [formData]="qs.rfqModel">
                <dxi-item itemType="group"
                    [caption]="(qs.rfqModel.typeId > 1 ? L('RFQ_NR') : L('AUCTION')) + ' - ' +qs.rfqModel.refNumber"
                    [colCount]="4">
                    <dxi-item [label]="{text: L('STATUS') }" dataField="statusName" editorType="dxTextBox" [colSpan]="2"
                        [editorOptions]="{ readOnly: true }">
                    </dxi-item>
                    <dxi-item [label]="{text: L('LIMIT_DATE_UPLOAD') }" dataField="dueDate" editorType="dxDateBox"
                        [colSpan]="2" [editorOptions]="{ 
                displayFormat: 'dd.MM.yyyy HH:mm', readOnly: true, type: 'datetime' }"></dxi-item>
                    <dxi-item [label]="{text: L('BUYER_OBSERVATIONS') }" dataField="partyNotes" editorType="dxTextArea"
                        [colSpan]="4" [editorOptions]="{ readOnly: true, height: 180 }">
                    </dxi-item>
                </dxi-item>
            </dx-form>
            <dx-form [formData]="qs.partyRfq" validationGroup="rfqPartyValidation" [readOnly]="qs.partyRfq.isWinner">
                <dxi-item itemType="group" [caption]="L('TRANSPORT_DETAILS')" [colCount]="4">
                    <dxi-item [label]="{text: L('INCO_TERM')}" dataField="incoTerm" editorType="dxSelectBox"
                        [colSpan]="2" [editorOptions]="{ dataSource: qs.incoTerm, valueExpr: 'code', displayExpr: 'name', 
                        readOnly: ![5,12].includes(qs.rfqModel.statusId) }">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item [label]="{text: L('EXECUTION_DAYS')}" dataField="executionDays" editorType="dxNumberBox"
                        [colSpan]="2" [editorOptions]="{ readOnly: ![5,12].includes(qs.rfqModel.statusId) }">
                    </dxi-item>
                    <dxi-item [label]="{text: L('PAYMENT_METHOD') }" editorType="dxSelectBox" dataField="paymentMethod"
                        [colSpan]="2" [editorOptions]="{ dataSource: qs.dsPaymentMethods, valueExpr: 'value', displayExpr: 'name', 
                            readOnly: ![5,12].includes(qs.rfqModel.statusId) }">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item [label]="{text: L('PAYMENT_DAYS')}" dataField="paymentDays" editorType="dxNumberBox"
                        [colSpan]="2"
                        [editorOptions]="{ readOnly: ![5,12].includes(qs.rfqModel.statusId), disabled: qs.partyRfq.paymentMethod != 1 }">
                    </dxi-item>
                    <dxi-item [label]="{text: L('TRANSPORT_TERM')}" dataField="transportState" editorType="dxSelectBox"
                        [colSpan]="2" [editorOptions]="{ dataSource: qs.transportState, valueExpr: 'id', displayExpr: 'name',
                    readOnly: ![5,12].includes(qs.rfqModel.statusId), onSelectionChanged: transportStateChanged }">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item [label]="{text: L('TRANSPORT_VALUE') }" dataField="transportValue"
                        editorType="dxNumberBox"
                        [editorOptions]="{ readOnly: ![5,12].includes(qs.rfqModel.statusId), disabled: qs.partyRfq.transportState != 2  }">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item [label]="{text: L('CURRENCY')}" dataField="transportCurrency" editorType="dxSelectBox"
                        [editorOptions]="{ dataSource: qs.currency, valueExpr: 'id', displayExpr: 'name', readOnly: ![5,12].includes(qs.rfqModel.statusId) }">
                    </dxi-item>
                    <dxi-item [label]="{text: L('OBSERVATIONS_CLARIFICATIONS') }" dataField="observations" [colSpan]="4"
                        editorType="dxTextArea" [helpText]="L('TRANSPORT_DETAILS_SAVE_INFO')"
                        [editorOptions]="{ readOnly: qs.rfqModel.statusId >= 20 }">
                    </dxi-item>
                </dxi-item>
            </dx-form>
        </div>
    </div>
    <div class="body-box">
        <dx-tab-panel [animationEnabled]="true" [swipeEnabled]="false" [selectedIndex]="qs.panelIndex">
            <dxi-item [title]="L('MATERIAL_LIST')" *ngIf="qs.rfqModel.id">
                <app-quotation-request></app-quotation-request>
            </dxi-item>
            <dxi-item [title]="L('REQUEST_DOCUMENTATION')" *ngIf="qs.rfqModel.id">
                <app-quotation-file [dsFile]="qs.requestFile" [allowAdd]="false" [allowDelete]="false">
                </app-quotation-file>
            </dxi-item>
            <dxi-item [title]="L('QUOTATION_LIST')" *ngIf="qs.rfqModel.id">
                <app-quotation-list></app-quotation-list>
            </dxi-item>
            <dxi-item [title]="L('CLARIFICATIONS')" *ngIf="qs.rfqModel.id">
                <app-clarification-list></app-clarification-list>
            </dxi-item>
            <dxi-item [title]="L('OFFER_ATTACHMENTS')" *ngIf="qs.rfqModel.id">
                <app-quotation-file [dsFile]="qs.rfqPartyFiles"
                    [allowAdd]="(!qs.isUpdateDisabled && this.qs.rfqModel.statusId == 5) || this.qs.discountVisible || this.qs.partyRfq.techStatus == 2"
                    [allowDelete]="(!qs.isUpdateDisabled && this.qs.rfqModel.statusId == 5) || this.qs.discountVisible || this.qs.partyRfq.techStatus == 2"></app-quotation-file>
            </dxi-item>
            <dxi-item [title]="L('CLARIFICATION_FILES')" *ngIf="qs.partyRfq.techStatus == 2">
                <app-quotation-file [dsFile]="qs.rfqPartyClarificationFiles" [allowAdd]="false"
                    [allowDelete]="false"></app-quotation-file>
            </dxi-item>
        </dx-tab-panel>
    </div>
</div>

<dx-load-panel [(visible)]="qs.loadingVisible"></dx-load-panel>

<ibis-edit-popup [edit]="edit" [showAdd]="false" [width]="'90vw'">
    <app-quotation-item-edit [rfqItems]="qs.rfqItems" [currency]="qs.currency" [dsPaymentMethods]="qs.dsPaymentMethods"
        [readOnly]="false"></app-quotation-item-edit>
</ibis-edit-popup>