import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent, iBisLanguageService, iBisUIService } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import notify from 'devextreme/ui/notify';
import { QuotationItemEditService } from '../../../app/common/quotation-item-edit/quotation-item-edit.service';
import { DataService } from 'src/app/services';
import { QuotationService } from '../quotation.service';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-quotation-list',
  templateUrl: './quotation-list.component.html',
  styleUrls: ['./quotation-list.component.css']
})
export class QuotationListComponent extends iBisViewComponent {

  isDiscountInputVisible: boolean;
  @ViewChild('qgrid') qgrid!: DxDataGridComponent;
  discountType: number = 2;
  discountNumber: any;
  constructor(public override edit: QuotationItemEditService, public qs: QuotationService,
    lg: iBisLanguageService, iui: iBisUIService, public ds: DataService) {
    super(edit, lg, iui, <any>null);
    this.id = 'riq_id';
    this.key = 'supplierQuotationGrid';
    this.isDiscountInputVisible = false;
  }

  onQuoteCellPrepared(ev: any) {
    if (ev.rowType == 'data' && ev.column.dataField == 'riq_isWinner' && ev.data.riq_isWinner != null
      && !ev.cellElement.classList.hasOwnProperty('red-txt') && !ev.cellElement.classList.hasOwnProperty('green-txt')) {
      ev.cellElement.classList.add(ev.data.riq_isWinner ? 'green-txt' : 'red-txt');
    }
  }

  calcTotaPrice = (row: any) => {
    if (row.riq_itemPrice != null && row.riq_quantity != null)
      return Math.round(row.riq_itemPrice * row.riq_quantity * 100) / 100;
    else return null;
  }

  getCurrency(id: string) {
    var item = this.qs.currency.find(r => r.id == id)
    return item ? item.name : '-';
  }

  override onDeleteClick = (ev: any) => {
    confirm(this.L('CONFIRM_DELETE'), this.L('CONFIRM')).then((resp) => {
      if (resp) {
        this.edit.onRemove(ev.row.data.riq_id).then((srvRsp: any) => {
          if (srvRsp) {
            notify(this.L('DELETED'), 'success', 3000);
            this.qs.initQuotationItem();
          }
        })
      }
    })
  }

  override onEditClick = (ev: any) => {
    const item = {
      id: ev.row.data.riq_id,
      rfqId: ev.row.data.riq_rfqId,
      materialId: ev.row.data.riq_materialId,
      partyId: ev.row.data.riq_partyId,
      manufacturer: ev.row.data.riq_manufacturer,
      quantity: ev.row.data.riq_quantity,
      isAlternate: ev.row.data.riq_isAlternate,
      alternateName: ev.row.data.riq_alternateName,
      itemPrice: ev.row.data.riq_itemPrice,
      currencyId: ev.row.data.riq_currencyId,
      uom: ev.row.data.uom_description,
      deliveryDays: ev.row.data.riq_deliveryDays,
      warrantyMonths: ev.row.data.riq_warrantyMonths,
      paymentDays: ev.row.data.riq_paymentDays,
      paymentMethod: ev.row.data.riq_paymentDays == 0 ? 0 : 1,
      observations: ev.row.data.riq_observations,
      isDiscount: this.qs.partyRfq.discountRequested
    }
    if (this.qs.party.enLanguage) {
      item.uom = ev.row.data.uom_descriptionEng;
    }
    this.edit.showPopup(item).then(resp => {
      if (resp) {
        notify(this.L('SAVED_SUCCESSFULLY'), 'success', 3000);
        this.qs.initQuotationItem();
      }
    });
  }

  onToolbarQuotation(ev: any) {
    super.toolbarPreparing(ev, false);

    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'plus',
        name: 'quotation',
        text: this.L('ADD_QUOTATION'),
        disabled: this.qs.isUpdateDisabled,
        visible: this.qs.commercialVisible,
        onClick: (ev: any) => {
          const quot = {
            isAlternate: false,
            partyId: this.qs.party.id,
            rfqId: this.qs.rfqModel.id,
            currencyId: this.qs.party.currencyId
          }
          this.edit.showPopup(quot).then((resp) => {
            if (resp) {
              notify(this.L('SUCCESS_ADD_QUOTATION'), 'success', 3000);
              this.qs.initQuotationItem();
            }
          })
        }
      },
      location: 'after'
    });
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'plus',
        name: 'fileAdd',
        text: this.L('UPLOAD_FILE'),
        visible: this.qs.rfqModel.statusId == 5 || this.qs.discountVisible || this.qs.partyRfq.techStatus == 2,
        onClick: (ev: any) => {
          this.qs.upload(this.qs.uniqueId).then(() => {
            notify(this.L('FILE_UPLOADED'), 'success', 2000);
          })
        }
      },
      location: 'after'
    });

    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'minus',
        name: 'discunt',
        text: this.L('APPLY_DISCOUNT'),
        visible: this.isDiscountInputVisible == false && this.qs.discountVisible,
        onClick: (evBtn: any) => {
          this.isDiscountInputVisible = true;
          this.discountNumber = 0;
          ev.component.repaint();
        }
      },
      location: 'after'
    });

    ev.toolbarOptions.items.unshift({
      widget: 'dxNumberBox',
      options: {
        visible: this.isDiscountInputVisible,
        onValueChanged: (discEv: any) => {
          this.discountNumber = discEv.component.option('value');
        },
        buttons: [{
          location: 'after',
          name: 'applyDiscount',
          options: {
            text: this.L('APPLY'),
            onClick: (ert: any) => {
              var selectedKeys = ev.component.getSelectedRowKeys();
              if (!selectedKeys.length || !this.discountType || !this.discountNumber) {
                notify(this.L('DISCOUNT_SELECTION_NEED'), 'error', 3000);
                return;
              }
              var obj: any = { quotes: selectedKeys, rfqp: this.qs.uniqueId };
              obj[this.discountType == 2 ? 'discountValue' : 'discountProc'] = this.discountNumber;
              this.qs.setDiscountForQuotes(obj).then(resp => {
                this.isDiscountInputVisible = false;
                this.qs.initQuotationItem();
                ev.component.repaint();
                this.discountNumber = 0;
              }, err => {
                this.lg.showError(err);
              })
            }
          }
        }]
      },
      location: 'after'
    });
    ev.toolbarOptions.items.unshift({
      widget: 'dxSelectBox',
      options: {
        dataSource: [{ id: 1, name: this.L('PERCENT_DISCOUNT') }, { id: 2, name: this.L('VALUE_DISCOUNT') }],
        valueExpr: 'id',
        displayExpr: 'name',
        width: 180,
        value: this.discountType,
        onValueChanged: (ev: any) => {
          this.discountType = ev.component.option('value');
        }
      },
      visible: this.isDiscountInputVisible,
      location: 'after'
    });
  }

}
