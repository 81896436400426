import { Injectable } from '@angular/core';
import { iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { locale } from 'devextreme/localization';
import { alert, confirm } from 'devextreme/ui/dialog';
import { DataService } from '../services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {
  gridHeight = 'calc(100vh - 150px)';
  panelIndex: number = 0;
  rfqModel: any = {};
  quotationList!: any[];
  currency: any[] = []
  party!: any;
  rfqItems: any;
  uniqueId!: string;
  requestFile: any;
  rfqPartyFiles: any;
  rfqPartyClarificationFiles: any;
  exchangeRate: any;
  partyRfq: any;
  rfqPartyStatus: string = '';
  isUpdateDisabled: boolean = false;
  partyRank: any;
  servicePrice: any[] = [];
  materialPrice: any[] = [];
  quotePrice: any[] = [];
  loadingVisible: any;
  transportState: any;
  incoTerm: any;
  hasWinnerQuotation: boolean = false;
  dsPaymentMethods: any[] = [];
  winnerQuote: any;

  constructor(private entity: iBisEntityService, private lg: iBisLanguageService, private ds: DataService,
    private http: HttpClient) { }

  public initQuotation(rfqpId?: string) {
    if (rfqpId && this.uniqueId != rfqpId) {
      this.uniqueId = rfqpId;
    }
    this.initRFQParty(rfqpId || this.uniqueId);
  }

  get commercialVisible() {
    return this.rfqModel && new Date(this.rfqModel.dueDate) > new Date() &&
      ((this.rfqModel.statusId == 5 && this.rfqModel.flowType == 1) || this.partyRfq.techStatus == 2 ||
        (this.rfqModel.statusId == 12 && this.rfqModel.flowType == 2));
  }

  unavailableVisible = (rowInfo: any) => {
    return this.commercialVisible && !rowInfo.row.data.unavailable;
  }

  get discountVisible() {
    return this.rfqModel && [10, 15, 18].indexOf(this.rfqModel.statusId) > -1 && this.partyRfq.discountRequested || this.partyRfq.techStatus != 2;
  }

  sendOffer(ev: any) {
    if (!ev.validationGroup.validate().isValid) {
      alert(this.lg.format('INCO_TERM_TRANSPORT_ALERT'), this.lg.format('ALERT'));
      return;
    }
    if (this.rfqModel.typeId > 1 && (!this.quotationList || !this.quotationList.length)) {
      alert(this.lg.format('QUOTATION_SEND_OFFER_ALERT'), this.lg.format('ALERT'));
      return;
    }
    this.partyRfq.rfqpId = this.uniqueId;
    this.entity.execute('SaveOffer', this.partyRfq).then((resp) => {
      const alertMsg = this.partyRfq.techStatus == 2 ? this.lg.format('CLARIFICATIONS_HAS_BEEN_SENT') : this.lg.format('OFFER_HAS_BEEN_SENT');
      alert(alertMsg, this.lg.format('ALERT'));
    })
  }

  private initRFQParty(rfqpId: string) {
    this.loadingVisible = true;

    this.entity.execute('GetRFQParty', { rfqp: rfqpId }).then(resp => {
      this.loadingVisible = false;
      if (resp && resp.length) {
        this.party = resp[0];
        this.partyRfq = this.setNewValues(this.partyRfq, resp[1]);
        this.rfqModel = resp[2];
        this.quotationList = resp[3];

        if (this.party.enLanguage) {
          locale('en');
          this.lg.locale('EN');
        }
      }
      if (this.rfqModel.statusId >= 20 && this.quotationList.find(x => x.riq_isWinner)) {
        this.hasWinnerQuotation = true
      }

      if ([10, 15, 18].indexOf(this.rfqModel.statusId) > -1 && this.partyRfq.discountRequested) {
        this.isUpdateDisabled = false;
        this.rfqModel.statusName = this.lg.format('TEMP_OPEN');
        this.panelIndex = 2;
      } else {
        if ([5, 12].indexOf(this.rfqModel.statusId) == -1 || new Date(this.rfqModel.dueDate) < new Date()) {
          this.isUpdateDisabled = true;
          this.rfqModel.statusName = this.lg.format('REQUEST_CLOSED');
        } else {
          this.isUpdateDisabled = false;
          if (this.rfqModel.flowType == 2) {
            this.rfqModel.statusName = this.rfqModel.statusId == 5 ? this.lg.format('TECHNICAL_OFFER') : this.lg.format('COMMERCIAL_OFFER');
          } else {
            this.rfqModel.statusName = this.lg.format('FULL_OFFER');
          }
        }
      }
    }, rr => {
      if (rr == 'CP151') {
        alert(this.lg.format('CP151'), this.lg.format('ALERT'));
      } else {
        this.lg.showError(rr);
      }
      this.loadingVisible = false;
    })

  }

  initQuotationItem() {
    this.loadingVisible = true;
    this.entity.execute('GetRFQItemParty', { rfqp: this.uniqueId }).then(resp => {
      this.loadingVisible = false;
      if (resp && resp.length) {
        this.rfqItems = resp[0];
        this.quotationList = this.parseQuotationList(resp[1]);
        this.currency = resp[2];
        this.exchangeRate = resp[3];
        this.requestFile = resp[4];
        this.rfqPartyFiles = resp[5];
        this.partyRank = resp[6];

        this.servicePrice = resp[7];
        this.materialPrice = resp[8];
        this.quotePrice = resp[9];
        this.rfqPartyClarificationFiles = resp[10];

        if (this.party.enLanguage) {
          this.rfqModel.partyNotes = this.rfqModel.partyNotesEng;
          this.rfqItems.forEach((el: any) => {
            el.m_name = el.m_nameEng || el.m_name;
          });
          this.quotationList.forEach((el: any) => {
            el.m_name = el.m_nameEng || el.m_name;
          })
        }

        this.transportState = this.party.enLanguage ? this.ds.static.transportStateEn : this.ds.static.transportState;
        this.incoTerm = this.party.enLanguage ? this.ds.static.incoTermEng : this.ds.static.incoTerm;
        this.dsPaymentMethods = this.party.enLanguage ? this.ds.static.paymentMethodsEng : this.ds.static.paymentMethods;
        this.winnerQuote = this.party.enLanguage ? this.ds.static.winnerQuoteEng : this.ds.static.winnerQuote;
      }
    }, rr => {
      if (rr == 'CP151') {
        alert(this.lg.format('CP151'), this.lg.format('ALERT'));
      } else {
        this.lg.showError(rr);
      }
      this.loadingVisible = false;
    })
  }

  parseQuotationList(qlds: any[]) {
    if (qlds.find(x => x.riq_unavailable)) {
      qlds.forEach(el => {
        if (el.riq_unavailable) {
          var item = this.rfqItems.find((x: any) => x.m_id == el.riq_materialId);
          item.unavailable = true;
        }
      });
    }
    return qlds.filter((x: any) => !x.riq_unavailable);
  }

  setNewValues(partyRfq: any, newPartyRfq: any): any {
    if (!partyRfq) { partyRfq = {} }
    for (const key of Object.keys(newPartyRfq)) {
      partyRfq[key] = newPartyRfq[key];
    }
    if (!partyRfq.paymentDays) {
      partyRfq.paymentMethod = 0;
    }
    return partyRfq;
  }

  removeFile(fileId: string) {
    return this.entity.execute('RemoveFile', { fileId: fileId });
  }

  setDiscountForQuotes(obj: any) {
    return this.entity.execute('DiscountRFQItemQuote', obj);
  }

  updateRFQRegister(reg: boolean) {
    if (!reg) {
      confirm(this.lg.format("ARE_YOU_SURE"), this.lg.format("ALERT")).then(val => {
        if (val) {
          this.entity.execute('RegisterRFQParty', { rfqp: this.uniqueId, register: reg }).then(() => {
            window.location.reload();
          })
        }
      })
    } else {
      this.entity.execute('RegisterRFQParty', { rfqp: this.uniqueId, register: reg });
    }
  }

  updateRFQDiscount(discountVal: boolean) {
    this.entity.execute('RegisterRFQParty', { rfqp: this.uniqueId, discountGranted: discountVal }).then(resp => {
      this.initRFQParty(this.uniqueId);
      alert(this.lg.format('THANK_YOU_FOR_RESPONSE'), this.lg.format('ALERT'));
    })
  }

  public upload(entityId: string): Promise<void> {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '.*');

    return new Promise((succes, error) => {
      input.addEventListener('change', (e) => {
        const file: File = (<any>input).files[0];
        if (Math.floor(file.size / 1048576) >= 10) {
          alert("Dimensiunea maxima admisa este de 10MB.", "Alerta");
          return;
        }
        this.uploadFile(entityId, file.name, file).subscribe((ress: any) => {
          succes(ress);
        }, err => {
          error(err);
        });
      }, false);
      input.click();
    });
  }

  uploadFile(entityId: string, fileName: string, file: File) {
    const formData: FormData = new FormData();
    formData.append('uploadFile', file, entityId + '_' + (fileName ?? file.name));
    return this.http.post(environment.defaultUrlServer + 'UploadFile', formData, { headers: this.Headers, responseType: 'text' as const });
  }

  private get Headers(): HttpHeaders {
    let headers = new HttpHeaders();
    for (const key in this.entity.Headers) {
      if (this.entity.Headers.hasOwnProperty(key)) {
        headers = headers.append(key, this.entity.Headers[key]);
      }
    }
    return headers;
  }

}
