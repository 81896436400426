import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { alert, confirm } from 'devextreme/ui/dialog';
import { DataService } from '../../../../app/services';
import { RfqItemListService } from '../../rfq-item-list/rfq-item-list.service';
import { RfqService } from '../../rfq.service';
import { RfqEditService } from '../rfq-edit.service';
import * as saveAs from 'file-saver';
import * as Excel from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { RfqEditAddManualQuotationService } from '../rfq-edit-add-manual-quotation/rfq-edit-add-manual-quotation.service';
import { RfqEditItemListPriceHistoryService } from './rfq-edit-item-list-price-history/rfq-edit-item-list-price-history.service';
import { OrderEditService } from 'src/app/order/order-edit/order-edit.service';
import { RfqEditItemListTechnicalSelectionService } from './rfq-edit-item-list-technical-selection/rfq-edit-item-list-technical-selection.service';
import { MaterialEditService } from 'src/app/admin/material/material-edit/material-edit.service';

@Component({
	selector: 'app-rfq-edit-item-list',
	templateUrl: './rfq-edit-item-list.component.html',
	styles: ['.no-margin h3, .no-margin p{ margin: 0}']
})
export class RfqEditItemListComponent extends iBisViewComponent implements OnInit, OnDestroy {

	@ViewChild('gridItemList') override grid!: DxDataGridComponent;
	itemExtended!: string;
	materialQuote!: CustomStore;
	sbs: any;
	uniqueSupplierId: any;
	constructor(public override edit: RfqItemListService, lg: iBisLanguageService, iui: iBisUIService,
		route: ActivatedRoute, public red: RfqEditService, public rqs: RfqService, public router: Router,
		public order: OrderEditService, public tus: RfqEditItemListTechnicalSelectionService,
		public mq: RfqEditAddManualQuotationService, public ds: DataService,
		public rph: RfqEditItemListPriceHistoryService, public material: MaterialEditService) {
		super(edit, lg, iui, route);
		this.id = 'ri_id';
		this.key = 'rfqEditItemListGrid';
	}

	ngOnInit() {
		this.sbs = this.red.rfqSubject.subscribe(() => {
			if (this.grid.instance) {
				this.grid.instance.refresh();
			}
		})
	}

	ngOnDestroy() {
		if (this.sbs) {
			this.sbs.unsubscribe();
		}
	}

	override onDeleteClick = (ev: any) => {
		ev.component.getDataSource().load().then((data: any) => {
			confirm(this.L("CONFIRM_DELETE"), this.L("ALERT")).then(resp => {
				if (resp) {
					const statusId = ev.row.data.ri_statusId == -1 ? -1 : 0;
					this.edit.updateRFQItem(ev.row.data.ri_id, statusId).then((sd: any) => {
						if (sd) {
							ev.component.refresh();
							notify(this.L('SUCCESS'), 'success', 2000);
							if (data.length == 1) {
								alert(this.L('CANCEL_RFQ_ON_LAST_ITEM'), this.L('ALERT')).then(() => {
									this.edit.closeRFQ(ev.row.data.ri_rfqId).then((rst) => {
										notify(this.L('SUCCESS'), 'success', 2000);
										this.router.navigateByUrl('rfquotation');
									})
								})
							}
						}
					});
				}
			})
		})
	}

	onEditMaterialClick = (ev: any) => {
		this.material.showPopup(ev.row.data.ri_materialId, true).then(resp => {
			if (resp) {
				notify(this.L('SUCCESS'), 'success', 2000);
			}
			ev.component.refresh();
		})
	}

	getCurrency(id: string) {
		var item = this.rqs.currency.find(r => r.id == id)
		return item ? item.name : '-';
	}

	onRFQItemPrepared(ev: any) {
		if (ev.rowType == 'data' && ev.data.ri_statusId == -1 && !ev.rowElement.classList.hasOwnProperty('red-txt')) {
			ev.rowElement.classList.add('red-txt');
		}
		if (ev.rowType == 'data' && ev.data.ri_statusId > 2 && !ev.rowElement.classList.hasOwnProperty('green-txt')) {
			ev.rowElement.classList.add('green-txt');
		}
	}

	onSupplierIncludeInitialized(ev: any, item: any) {
		this.itemExtended = item.ri_id;
		if (ev.component.option('dataSource') == null) {
			ev.component.option('dataSource', this.red.getSupplierInclude(item.ri_id));
		}
	}

	onExcludeSupplier = (ev: any) => {
		this.red.excludeSupplier(ev.row.data.rp_partyId, this.itemExtended).then(() => {
			notify(this.L('SUCCESS'), 'success', 3000);
			ev.component.refresh();
		}, err => {
			this.lg.showError(err);
		})
	}

	onIncludeSupplier = (ev: any) => {
		this.red.includeSupplier(ev.row.data.pii_id).then(() => {
			notify(this.L('SUCCESS'), 'success', 3000);
			ev.component.refresh();
		}, err => {
			this.lg.showError(err);
		})
	}

	onSupplierPrepared(ev: any) {
		if (ev.rowType === 'data' && ev.data.pii_id != null) {
			ev.cellElement.classList.add('disabled-txt');
		}
	}

	getValue(id: string, arr: any[], field: string, value: string) {
		var item = arr.find(r => r[field] == id)
		return item ? item[value] : '-';
	}

	visibleExclude = (ev: any) => {
		return ev.row.data && ev.row.data.pii_id == null && this.red.model.statusId <= 5 && this.red.model.statusId >= 0;
	}
	visibleInclude = (ev: any) => {
		return ev.row.data && ev.row.data.pii_id != null && this.red.model.statusId <= 5 && this.red.model.statusId >= 0;
	}
	addQuoteVisible = (ev: any) => {
		return ev.row.data && ev.row.data.ri_statusId < 2 && [0, 20].includes(this.red.model.statusId) && this.red.model.typeId == 3;
	}
	deleteVisible = (ev: any) => {
		return ev.row.data && ev.row.data.ri_statusId < 2;
	}
	onOrderVisible = (ev: any) => {
		return ev.row.data && ev.row.data.oh_id != undefined;
	}

	onAddQuotationClick = (ev: any) => {
		if (!this.uniqueSupplierId) {
			this.red.supplier.load().then((data: any) => {
				this.uniqueSupplierId = data[0].rp_partyId;
				this.showAddQuotationItem(ev.row.data.ri_materialId, ev.row.data.ri_quantity);
			})
		} else {
			this.showAddQuotationItem(ev.row.data.ri_materialId, ev.row.data.ri_quantity);
		}

	}

	onPriceHistoryClick = (ev: any) => {
		this.rph.title = parseInt(ev.row.data.m_code) + '-' + ev.row.data.m_name;
		this.rph.showPopup(ev.row.data.ri_materialId, true);
	}

	openOrder = (ev: any) => {
		this.order.showPopup(ev.row.data.oh_id, true);
	}

	openTechnicalSelection = (ev: any) => {
		this.tus.showPopup(ev.row.data.ri_id, true).then(resp => {
			this.red.rfqItems.reload();
		})
	}

	showAddQuotationItem(materialId: string, quantity: number) {
		this.mq.showPopup({
			rfqId: this.red.model.id,
			materialId: materialId,
			quantity: quantity,
			currencyId: this.rqs.currency.find(x => x.name == 'RON')?.id,
			partyId: this.uniqueSupplierId,
			unavailable: false
		}, true).then((resp) => {
			if (resp) {
				notify(this.L("SUCCESS"), 'success', 3000);
				this.red.rfqSubject.next(this.red.model);
			}
		})
	}

	public materialFilter(filterValue: any) {
		return [['m_code', 'contains', filterValue], 'OR', ['m_name', 'contains', filterValue], 'OR', ['m_nameEng', 'contains'], filterValue];
	}

	public logisticFilter(filterValue: any) {
		return [['dpz_description', 'contains', filterValue]];
	}

	override toolbarPreparing(ev: any, add: boolean) {
		super.toolbarPreparing(ev, add);
		ev.toolbarOptions.items.unshift({
			widget: 'dxButton',
			options: {
				icon: 'plus',
				text: this.L('ADD_REQUEST_ITEM'),
				visible: this.red.model.statusId == 0 || this.red.model.statusId == 5,
				onClick: (eb: any) => {
					this.edit.showPopup(this.red.model.id, true).then((resp) => {
						if (resp) {
							notify(this.L('ADD_SUCCESSFULL'), 'success', 3000);
							ev.component.refresh();
						}
					})
				}
			},
			location: 'after'
		});
	}

	onExporting(e: any) {
		e.component.beginUpdate();
		var cols = ["m_code", "uom_name", "ri_quantity", "ri_price", "ri_currencyId", "ri_deliveryDate", "ri_reason", "ri_docType", "pw_name", "pf_name", "ri_contractNo", "ri_contractPosNo", "ri_department", "ri_created"];
		cols.forEach(col => {
			e.component.columnOption(col, 'visible', true);
		})

		var exCols = [this.L("TYPE"), this.L("DELIVERY"), this.L("SUPPLIER"), "beneficiaryColumn", this.L('SAP')];
		exCols.forEach(col => {
			e.component.columnOption(col, 'visible', false);
		})

		const workbook = new Excel.Workbook();
		const worksheet = workbook.addWorksheet('Sheet1');

		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
			customizeCell: ({ gridCell, excelCell }) => {
				if (gridCell?.rowType == 'data') {
					if (gridCell.column?.caption === "Denumire") {
						excelCell.value = gridCell.data.m_name;
					}
					if (gridCell.column?.dataField === "ri_currencyId") {
						excelCell.value = this.getCurrency(gridCell.data.ri_currencyId);
					}
					if (gridCell.column?.dataField === "ri_userId") {
						excelCell.value = this.getValue(gridCell.data.ri_userId, this.rqs.buyer, 'u_id', 'u_fullName');
					}
					if (gridCell.column?.dataField === "m_code") {
						excelCell.value = this.ds.stringZero({ value: gridCell.data.m_code });
					}
					if (gridCell.column?.caption === "Solicitant") {
						excelCell.value = gridCell.data.ri_requesterName;
					}
				}
			}

		}).then(() => {
			workbook.xlsx.writeBuffer()
				.then((buffer: BlobPart) => {
					saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Lista_necesare_' + this.red.model.refNumber + '.xlsx');
				});
		}).then(() => {
			cols.forEach(col => {
				e.component.columnOption(col, 'visible', false);
			})
			exCols.forEach(col => {
				e.component.columnOption(col, 'visible', true);
			})
			e.component.endUpdate();
		});
		e.cancel = true;
	}

}
